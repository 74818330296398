<header class="sticky header-tools" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-right">
            <ul class="header-dropdown">
              <li><i class="fa fa-phone" aria-hidden="true"></i>{{ "info and assistance" | translate }} &nbsp;&nbsp;</li>
              <li  [routerLink]="['/login']">
                <i class="fa fa-user" aria-hidden="true"></i>{{ "login" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <div class="toggle-nav" (click)="mainMenuToggle()" style="padding-right: 25px;">
                <i class="fa fa-bars sidebar-bar"></i>
              </div>
              <div class="brand-logo">
                <a routerLink="/" id="headerlogo">
                  <img src="assets/fotodp/logo/logo_fotodp.png" class="img-fluid" alt="fotoDP">
                </a>
              </div>
            </div>
            <div class="menu-left pull-left">
              <div>
                <app-menu-top></app-menu-top>
              </div>
              <div style="padding-left: 15px;">
               <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>