import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MenuItem } from './menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public Menutabs: any;
  
  constructor(private http: HttpClient) { }

  private get menutabs(): Observable<MenuItem[]> {
    this.Menutabs = this.http.get<MenuItem[]>('assets/fotodp/json-data/menu.json').pipe(map(data => data));
    this.Menutabs.subscribe((next: any) => { localStorage['menu'] = JSON.stringify(next) });
    return this.Menutabs = this.Menutabs.pipe(startWith(JSON.parse(localStorage['menu'] || '[]')));
  }

  public get getMenutabs(): Observable<MenuItem[]> {
    return this.menutabs;
  }
}
