import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { MenuService } from './menu.service';
import { MenuItem } from './menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: MenuItem[];

  constructor(private router: Router, public navServices: NavService, private menuService: MenuService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.initialize();
  }


  initialize(): void {
    // this.menuItems = MENU;
    this.menuService.getMenutabs.subscribe(response => {
     console.log(response)
     this.menuItems = response;
   });
   }
 
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
