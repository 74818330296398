import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { HomeComponent } from './dp-home/home/home.component';
import { HeaderNavbarComponent } from './dp-home/header-navbar/header-navbar.component';
import { FooterComponent } from './dp-home/footer/footer.component';
import { MenuTopComponent } from './dp-home/header-navbar/menu-top/menu-top.component';
import { SettingsComponent } from './dp-home/header-navbar/settings/settings.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ServicesComponent } from './dp-home/services/services.component';
import { ProductBoxComponent } from './dp-products/product-box/product-box.component';
import { ProductListComponent } from './dp-products/product-list/product-list.component';
import { ProductDetailComponent } from './dp-products/product-detail/product-detail.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductPaginationComponent } from './dp-products/widgets/product-pagination/product-pagination.component';
import { ProductPriceComponent } from './dp-products/widgets/product-price/product-price.component';
import { ProductBrandsComponent } from './dp-products/widgets/product-brands/product-brands.component';
import { ProductCategoriesComponent } from './dp-products/widgets/product-categories/product-categories.component';
import { ProductBreadcrumbComponent } from './dp-products/widgets/product-breadcrumb/product-breadcrumb.component';
import { ContactComponent } from './dp-contacts/contact/contact.component';
import { ProductGridComponent } from './dp-products/widgets/product-grid/product-grid.component';
import { ProductBoxLargeComponent } from './dp-products/widgets/product-box-large/product-box-large.component';
import { ProductTypeComponent } from './dp-products/widgets/product-type/product-type.component';
import { ProductCompareComponent } from './dp-compare/product-compare/product-compare.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
    HomeComponent,
    HeaderNavbarComponent,
    FooterComponent,
    MenuTopComponent,
    SettingsComponent,
    ServicesComponent,
    ProductBoxComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductPaginationComponent,
    ProductPriceComponent,
    ProductBrandsComponent,
    ProductCategoriesComponent,
    ProductBreadcrumbComponent,
    ContactComponent,
    ProductGridComponent,
    ProductBoxLargeComponent,
    ProductTypeComponent,
    ProductCompareComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NgImageSliderModule,
    NgxPayPalModule,
    Ng5SliderModule,
    InfiniteScrollModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
