<!-- Home slider start-->
<section class="p-0 height-85 tools_slider">
    <div class="home-slider">
        <div class="home text-center image-repeate">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="slider-contain">
                            <div>
                                <h4></h4>
                                <h2>{{ "welcome to fotodp" | translate }}</h2>
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'tools'}"
                                    class="btn btn-solid">{{ "products" | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tools-parts" id="tools-move">
                    <img src="assets/fotodp/logo/Canon-VS-Sony-VS-Nikon.png" class="img-fluid img-home" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Home slider End -->

<!-- service section start -->
<section class="banner-padding absolute-banner pb-0 tools-service">
    <div class="container absolute-bg">
        <div class="service p-0 ">
            <app-services></app-services>
        </div>
    </div>
</section>
<!-- service section end -->


<!-- Categories start-->
<div class="container">
    <div class="row">
        <div class="col">
            <div class="section-title">
                <h5>{{ "our products" | translate }}</h5>
            </div>
            <div class="pagitation-owl">
                <owl-carousel-o [options]="CategorySliderConfig">
                    <ng-container *ngFor="let category of categories">
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']"
                                    [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img [src]="category.image" alt="">
                                        <!--class="w-auto"-->
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}">
                                        <h5>{{ category.title }}</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
<!-- categories End -->


<!-- Tab product End -->
<div class="title1 section-t-space">
    <h4></h4>
    <h2 class="title-inner1">Offerte speciali</h2>
</div>
<section class="section-b-space p-t-0">
    <div class="container">
        <div class="row">
            <div class="col theme-tab">
                <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab *ngFor="let collection of productCollections">
                        <ng-template ngbTabTitle>{{collection}}</ng-template>
                        <ng-template ngbTabContent>
                            <div class="no-slider row">
                                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                    <div class="product-box">
                                        <app-product-box [product]="product" [currency]="productService?.Currency"
                                            [cartModal]="true">
                                        </app-product-box>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>
</section>
<!-- Tab product End -->
<div  class="text-center">
    <button type="submit" class="btn btn-solid">{{ "all products" | translate }}</button>
</div>
<!-- Brands start-->
<div class="container">
    <div class="row">
        <div class="col">
            <div class="section-title">
                <h5>{{ "our brands" | translate }}</h5>
            </div>
            <div class="slide-5">
                <div class="pagitation-owl">
                    <owl-carousel-o [options]="BrandSliderConfig">
                        <ng-container *ngFor="let brand of brands ">
                            <ng-template carouselSlide>
                                <div class="brand-block">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}">
                                        <div class="brand-image">
                                            <img [src]="brand.image" alt="">
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Brand End -->