<app-breadcrumb [title]="'products'" [breadcrumb]="'products'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> {{ "back" | translate }}
                                </span>
                            </div>
                            <!-- Category filter start -->
                            <app-product-categories></app-product-categories>
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <app-product-type [products]="products" [title]="'Tipo'" [brands]="categories" (brandsFilter)="updateFilter($event)">
                            </app-product-type>
                            <app-product-brands [products]="products" [title]="'Brand'" [brands]="brands" (brandsFilter)="updateFilter($event)">
                            </app-product-brands>
                            <app-product-price [min]="minPrice" [max]="maxPrice" (priceFilter)="updateFilter($event)">
                            </app-product-price>
                        </div>
                        <!-- silde-bar colleps block end here--> 
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="top-banner-wrapper">
                                    <div class="top-banner-content small-section">
                                        <h4> {{ "products" | translate }}</h4>
                                        <h5>
                                        </h5>
                                        <p>
                                            {{ "we answer the phone from 9.00 to 12.00 and from 15.30 to 18.30." | translate }} &nbsp; <i class="fa fa-phone"></i>&nbsp; <strong>+39 3386031713  no SMS no WHATSAPP.</strong> <br> 
                                            {{ "for information on the status of an order or shipments send an email to" | translate }} &nbsp; <i class="fa fa-envelope-o"></i>&nbsp; <strong>infofotodp@gmail.com.</strong>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> {{ "filter" | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="product-filter-tags">
                                    <li class="mr-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            {{ "clear all" | translate }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="collection-product-wrapper" id="products">
                                   <app-product-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-product-grid>

                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                               <app-product-box-large [product]="product" [currency]="productService?.Currency" [thumbnail]="false" [cartModal]="true" [loader]="true">
                                               </app-product-box-large>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                                <p>Please check if you have misspelt something or try searching with other words.</p>
                                                <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">{{ "continue shopping" | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <app-product-pagination [products]="products" [paginate]="paginate" (setPage)="setPage($event)">
                                    </app-product-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->