<!--footer section -->
<footer [ngClass]="class">
    <div class="light-layout">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-6">
                        <div class="footer-title footer-mobile-title">
                            <h4></h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img src="assets/fotodp/logo/logo_fotodp.png" alt="fotoDP" style="max-height: 40px;">
                            </div>
								<ul class="contact-details">
									<li><i class="fa fa-map-marker"></i>&nbsp; via Righini 05 - 48125 Ravenna</li>
									<li><i class="fa fa-phone"></i>&nbsp; +39 3386031713 </li>
									<li><i class="fa fa-envelope-o"></i>&nbsp; <a>infofotodp@gmail.com</a></li>
								</ul>
						</div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
							<h4>{{ "know it all first!" | translate }}</h4>
							<p>{{ "signing up to our newsletter" | translate }}</p>
                            <form >
                                <div class="form-group">
									<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="{{ 'enter your email' | translate }}">
                                    <button type="submit" class="btn btn-solid">{{ "subscribe" | translate }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
   
    <div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} fotodp srl P.Iva: IT 02694770393</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<!--<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->