import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './dp-home/home/home.component';
import { ProductListComponent } from './dp-products/product-list/product-list.component';
import { ContactComponent } from './dp-contacts/contact/contact.component';
import { ProductDetailComponent } from './dp-products/product-detail/product-detail.component';
import { ProductCompareComponent } from './dp-compare/product-compare/product-compare.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: []
  },
  {
    path: 'shop',
    component: ProductListComponent
  },
  {
    path: 'shop/:id',
    component: ProductDetailComponent
  },
  {
    path: 'compare',
    component: ProductCompareComponent
  },
  {
    path: 'contacts',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
