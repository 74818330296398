import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/shared/components/menu/menu.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { MenuItem } from '../menu.model';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit {
  public menuItems: MenuItem[];

  constructor(private router: Router, public navServices: NavService, private menuService: MenuService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.initialize();
  }


  initialize(): void {
    this.menuService.getMenutabs.subscribe(response => {
     this.menuItems = response;
   });
   }
 
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }
}
