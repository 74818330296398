import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/shared/classes/product';
import { SizeModalComponent } from 'src/app/shared/components/modal/size-modal/size-modal.component';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from 'src/app/shared/data/slider';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  
  public product: Product = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  
  @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  constructor(private route: ActivatedRoute, private router: Router,
    public productService: ProductService) { 
      //this.route.data.subscribe(response => this.product = response.data );
      console.log(route.params['id'])
    }

  ngOnInit(): void {
    this.product = {
      id: 1,
      title: "FujiFilm X-T30 Mark II Corpo SILVER",
      description: "è l’evoluzione della X-T30 sia a livello hardware sia software. Fujifilm X-T30 II porta con sé tutte le novità software degli ultimi 2 anni tra cui il nuovo sistema AF. L’algoritmo dell’autofocus, infatti, è quello nuovo della Fujifilm X-T4, veloce (solo 0,02 secondi di messa a fuoco) e preciso.",
      type: "fotocamere digitali",
      brand: "fujifilm",
      collection: [
      ],
      category: "fotocamere digitali",
      price: 759,
      sale: false,
      stock: 20,
      new: false,
     
      variants: [
          {
              variant_id: 10,
              id: 1,
              sku: "fujifilm",
              color: "mirrorless",
              image_id: 13311
          }
      ],
      images: [
          {
              image_id: 13311,
              id: 133,
              alt: "electronics",
              src: "assets/fotodp/prodotti/fujifilm-x-t30-ii-body-black.jpg",
              variant_id: [
                  10
              ]
          }
      ]
  }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }
    }
    return uniqSize
  }

  selectSize(size) {
    this.selectedSize = size;
  }
  
  // Increament
  increment() {
    this.counter++ ;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  // Add to cart
  async addToCart(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if(status)
      this.router.navigate(['/shop/cart']);
  }

  // Buy Now
  async buyNow(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if(status)
      this.router.navigate(['/shop/checkout']);
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

}
