<app-breadcrumb [title]="'Compare'" [breadcrumb]="'Compare'"></app-breadcrumb>
<!-- section start -->
<section class="compare-section section-b-space ratio_asos">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="slide-4">
                    <owl-carousel-o [options]="CompareSliderConfig">
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>Canon 90D+18-135- IS Usm</h5>
                                        </a>
                                        <h5>€ 1,409.00</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5 style="color: brown;">Descrizione</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5 style="color: green;">Sensore immagine</h5>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>CMOS da 22,3 x 14,8 mm</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pixel effettivi</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Circa 32.5 MP</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pixel totali</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Circa 34.4 MP</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Rapporto di visualizzazione</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>3:2</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Filtro low-pass</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Integrato/fisso</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pulizia sensore</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Sistema integrato di pulizia EOS</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo filtro colore</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Colori primari</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5 style="color: green;">Processore di immagine</h5>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>DIGIC 8</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Stabilizzazione dell’immagine</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>-</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>Canon Eos 6D Mark II + 24-105 F/4 L IS USM</h5>
                                        </a>
                                        <h5>€ 2,169.00</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5 style="color: brown;">Descrizione</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5 style="color: green;">Sensore immagine</h5>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Circa CMOS da 35,9 x 24 mm</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pixel effettivi</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Circa 26,2 megapixel</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pixel totali</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Circa 27,1 megapixel</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Rapporto di visualizzazione</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>3:2</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Filtro low-pass</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Integrato/fisso</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Pulizia sensore</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Sistema integrato di pulizia EOS</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo filtro colore</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Colori primari</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5 style="color: green;">Processore di immagine</h5>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Tipo</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>DIGIC 7</p>
                                            </div>
                                        </div>
                                        <div class="detail-part">
                                            <div class="title-detail">
                                                <h5>Stabilizzazione dell’immagine</h5>
                                            </div>
                                            <div class="inner-detail">
                                                <p>Stabilizzatore dell’immagine su obiettivo compatibile
                                                    Filmati: IS digitale integrato a 5 assi disponibile.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                       <!--<ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>-->
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->