import { Component, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from 'src/app/shared/classes/product';
import { BrandSlider, CategorySlider, HomeSlider } from 'src/app/shared/data/slider';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public products: Product[] = [];
  public productCollections: any[] = [];
  
  public CategorySliderConfig: OwlOptions = CategorySlider;
  public BrandSliderConfig: OwlOptions = BrandSlider;
  public HomeSliderConfig: any = HomeSlider;

  public imageObject: Array<object> = [{
    image: 'assets/fotodp/brands/nikon.png',
    thumbImage: 'assets/fotodp/brands/nikon.png',
    alt: '',
    order: 1
},
{
  image: 'assets/fotodp/brands/canon.png',
  thumbImage: 'assets/fotodp/brands/canon.png',
  alt: '',
  order: 2
},
{
  image: 'assets/fotodp/brands/sony.png',
  thumbImage: 'assets/fotodp/brands/sony.png',
  alt: '',
  order: 3
},
{
  image: 'assets/fotodp/brands/fujifilm.png',
  thumbImage: 'assets/fotodp/brands/fujifilm.png',
  alt: '',
  order: 4
},
{
  image: 'assets/fotodp/brands/tamron.png',
  thumbImage: 'assets/fotodp/brands/tamron.png',
  alt: '',
  order: 5
}
];
  // Categories
  public categories = [{
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'reflex nikon'
  }, {
    image: 'assets/fotodp/icon-category/CANON-reflex_eos-5ds-r_1-300x300.jpg',
    title: 'reflex canon'
  }, {
    image: 'assets/fotodp/icon-category/sony-mirrorless-300x300.jpg',
    title: 'reflex sony'
  }, {
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'mirroless nikon'
  }, {
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'mirroless canon'
  }, {
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'mirroless sony'
  },{
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'mirroless fujifilm'
  },{
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'obiettivi nikon'
  }, {
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'obiettivi canon'
  }, {
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'obiettivi sony'
  },{
    image: 'assets/fotodp/icon-category/NIKON-D850-reflex-300x300.jpg',
    title: 'obiettivi tamron'
  }];
  // Brands
  public brands = [{
    image: 'assets/fotodp/brands/nikon.png',
    title: ''
  }, {
    image: 'assets/fotodp/brands/canon.png',
    title: ''
  }, {
    image: 'assets/fotodp/brands/sony.png',
    title: ''
  }, {
    image: 'assets/fotodp/brands/fujifilm.png',
    title: ''
  }, {
    image: 'assets/fotodp/brands/tamron.png',
    title: ''
  }];
  
  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(response => {
      this.products = response.filter(item => item.type == 'fotocamere digitali' || item.type == 'obiettivi');
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });
  }
  
  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#6d7e87');
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }
  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

}
