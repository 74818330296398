<app-breadcrumb [title]="'Contact'" [breadcrumb]="'Contact'"></app-breadcrumb>
<!--section start-->
<section class=" contact-page section-b-space">
    <div class="container">
        <div style="padding-top: 40px;"></div>
        <div class="row section-b-space">
            <!--<div class="col-lg-7 map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"   allowfullscreen></iframe>
            </div>-->
            <div class="col-lg-6">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <h6>{{ "address" | translate }}</h6>
                            </div>
                            <div class="media-body">
                                <p>via Righini 05 - 48125 Ravenna</p>
                                <p>{{ "italy" | translate }}</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/phone.png" alt="Generic placeholder image">
                                <h6>{{ "contact us" | translate }}</h6>
                            </div>
                            <div class="media-body">
                                <p>{{ "we answer the phone from 9.00 to 12.00 and from 15.30 to 18.30." | translate }}
                                </p>
                                <p>+39 338 603 1713</p>
                            </div>
                        </li>

                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/email.png" alt="Generic placeholder image">
                                <h6>{{ "Email" | translate }}</h6>
                            </div>
                            <div class="media-body">
                                <p>{{ "for information on the status of an order or shipments send an email to" |
                                    translate }}</p>
                                <p>infofotodp@gmail.com</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <form class="theme-form">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label for="name">{{ "First Name" | translate }}</label>
                            <input type="text" class="form-control" id="name"
                                placeholder="{{ 'First Name' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="last-name">{{ 'Last Name' | translate }}</label>
                            <input type="text" class="form-control" id="last-name"
                                placeholder="{{ 'Last Name' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="phone">{{ "Phone number" | translate }}</label>
                            <input type="text" class="form-control" id="phone"
                                placeholder="{{ 'Phone number' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">{{ "Email" | translate }}</label>
                            <input type="text" class="form-control" id="email" placeholder="{{ 'Email' | translate }}"
                                required="">
                        </div>
                        <div class="col-md-12">
                            <label for="exampleFormControlTextarea1">{{ "Write Your Message" | translate }}</label>
                            <textarea class="form-control" placeholder="{{ 'Write Your Message' | translate }}"
                                id="exampleFormControlTextarea1" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" type="submit">{{ "Send Your Message" | translate }}</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-12">
                <form class="theme-form">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label for="name">{{ "First Name" | translate }}</label>
                            <input type="text" class="form-control" id="name" placeholder="{{ 'First Name' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="last-name">{{ 'Last Name' | translate }}</label>
                            <input type="text" class="form-control" id="last-name" placeholder="{{ 'Last Name' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="phone">{{ "Phone number" | translate }}</label>
                            <input type="text" class="form-control" id="phone" placeholder="{{ 'Phone number' | translate }}" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">{{ "Email" | translate }}</label>
                            <input type="text" class="form-control" id="email" placeholder="{{ 'Email' | translate }}" required="">
                        </div>
                        <div class="col-md-12">
                            <label for="exampleFormControlTextarea1">{{ "Write Your Message" | translate }}</label>
                            <textarea class="form-control" placeholder="{{ 'Write Your Message' | translate }}" id="exampleFormControlTextarea1" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" type="submit">{{ "Send Your Message" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>-->
    </div>
</section>
<!--Section ends-->